import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { config } from '@manzuko/shared/config';
import { Store } from '@ngrx/store';
import { JsonLdDocument } from 'jsonld';

import { selectAuthUserIsAdmin } from 'app/data-access/auth/auth.selectors';
import { FooterContactComponent } from 'app/shared/footer-contact/footer-contact.component';
import { JsonLdComponent } from 'app/shared/json-ld/json-ld.component';
import { NewsletterComponent } from 'app/shared/newsletter/newsletter.component';
import { RouterLinkPipe } from 'app/shared/pipes/router-link.pipe';

import packageInfo from '../../../../package.json';

@Component({
  standalone: true,
  selector: 'mzk-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [
    AsyncPipe,
    RouterLink,
    RouterLinkPipe,
    NewsletterComponent,
    FooterContactComponent,
    JsonLdComponent,
    TranslocoDirective
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  readonly #store = inject(Store);

  isAdmin = this.#store.selectSignal(selectAuthUserIsAdmin);

  version: string = packageInfo.version;

  organization: JsonLdDocument = {
    '@context': 'https://schema.org/',
    '@type': 'Organization',
    logo: {
      '@type': 'ImageObject',
      url: config.logo
    },
    ...config.organization,
    ...config.website
  };

  website = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    ...config.website
  };

  localBusiness = {
    '@context': 'https://schema.org/',
    '@type': 'LocalBusiness',
    name: config.website.name,
    address: config.organization.address,
    geo: {
      '@type': 'GeoCoordinates',
      ...config.geo
    },
    openingHoursSpecification: {
      '@type': 'OpeningHoursSpecification',
      ...config.openingHoursSpecification
    },
    url: config.website.url
  };
  getFullYear(): number {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }
}
