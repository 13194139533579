@if (lang$ | async; as lang) {
  <ng-container *transloco="let t; read: 'shop.search'">
    <form
      [formGroup]="searchForm"
      (ngSubmit)="navigateToSearchResultsPage(lang)"
      class="font-light z-[103] right-0 md:right-auto">
      <mzk-json-ld [json]="siteLinkSearchBox" />
      <div class="flex">
        <input
          type="text"
          [formControl]="searchInput"
          (click)="showSearch()"
          placeholder="{{ t('placeholder') }}"
          data-test="search-input"
          class="max-md:w-full font-light max-md:py-3 max-md:pl-14 max-md:pr-2 max-md:rounded-full bg-gray-50 border text-gray-900 focus:ring-fuchsia-800 focus:border-fuchsia-800 block flex-1 max-md:min-w-[128px] md:border-gray-300 border-l-brand max-md:border-r-brand transition-colors md:w-40 md:rounded-l-md md:min-w-0 z-[105]" />
        <button
          type="submit"
          aria-label="Szukaj"
          class="max-md:hidden inline-flex items-center pl-2 pr-3 text-sm text-gray-900 rounded-r-md border bg-white border-l-0 border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600 z-[105]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="inline-flex w-6 h-6 text-gray-400">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
          </svg>
        </button>
        <a
          class="md:hidden absolute z-[110] p-0.5 px-1"
          routerLink="/"
          data-test="header-logo-small"
          title="Sklep z Koralikami i Akcesoriami do Wyrobu Biżuterii - Manzuko">
          <img
            class="w-10 h-10 m-[3px]"
            src="https://manzuko.b-cdn.net/assets/icons/logo.svg"
            loading="eager"
            fetchpriority="high"
            alt="Logo Manzuko" />
        </a>
      </div>
      @if (search$ | async; as response) {
        @if (isOpen$ | async) {
          <div
            class="bg-white p-3 pt-8 fixed top-12 max-h-[90vh] left-2 right-2 max-md:bottom-20 rounded-xl flex flex-col space-y-5 md:space-y-0 md:space-x-5 md:flex-row md:left-[10%] md:right-[10%] lg:left-[20%] lg:right-[20%] xl:left-[30%] xl:right-[30%] z-[103]">
            <div class="flex flex-col space-y-2">
              <h6>Kategorie:</h6>
              <!-- {{response.categories|json}} -->
              <ul class="flex flex-row overflow-x-auto md:flex-col md:overflow-hidden">
                @for (category of response?.categories?.results?.slice(0, 4); track category?.id) {
                  <li
                    class="flex flex-col items-center text-center max-h-24 min-w-24 overflow-hidden">
                    <mzk-category-thumb [category]="category" size="cart" class="block w-10" />
                    <h6 [routerLink]="category?.path" class="text-sm">{{ category?.name }}</h6>
                  </li>
                }
              </ul>
            </div>
            <div class="flex flex-col flex-grow max-h-full space-y-2">
              <h6>{{ t('foundProducts') }}</h6>
              <ul
                class="flex flex-col flex-grow max-h-[39vh] md:max-h-[50vh] overflow-y-scroll md:touch-pan-up">
                @for (product of response?.products?.results; track product?.id) {
                  <li class="m-1 border-b p-1 md:m-2 md:p-4">
                    <a [routerLink]="product?.path">
                      <div class="flex flex-row">
                        <div class="mr-2 w-15 md:w-30">
                          <mzk-product-thumb
                            [product]="product"
                            class="w-8 h-8 rounded-full m-auto md:w-16 md:h-16" />
                        </div>
                        <div class="m-auto w-50 h-25 break-words">
                          {{ product?.name }}
                        </div>
                      </div>
                    </a>
                  </li>
                }
                @if (response?.products?.results?.length < 1 && searchForm?.value?.searchInput) {
                  <li class="m-1 font-semibold text-xm p-1 md:m-2 md:p-4">
                    <p class="p-1 font-normal text-sm">
                      {{ t('noResults') }}
                    </p>
                  </li>
                }
              </ul>
              @if (response?.products?.pagination?.length > 0) {
                <p class="flex font-semibold text-center p-1 mt-1">
                  <a class="cursor-pointer" (click)="navigateToSearchResultsPage(lang)">
                    {{ t('more') }} ({{ response?.products?.pagination?.length }})
                  </a>
                </p>
              }
              @if (isAdmin$ | async) {
                <div class="flex font-semibold text-center p-1 mt-1">
                  <label class="flex items-center">
                    <input type="checkbox" class="cursor-pointer mr-2" [formControl]="strict" />
                    <div>
                      <p class="p-1 font-normal text-sm">Pokaż dokładne wyszukania</p>
                    </div>
                  </label>
                </div>
              }
            </div>
          </div>
        }
      }
    </form>
    @if (isOpen$ | async; as isOpen) {
      <div
        class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-[102] transition-all cursor-close"
        (click)="closeSearch()"
        [ngClass]="
          isOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
        "></div>
    }
  </ng-container>
}

